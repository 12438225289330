<template>
  <div>
    <Banner>
      <img style="width:600px;height:379px;bottom:46px" src="@/assets/image/productCenter/verifySign-banner-bg.png" alt="签名验签">
      <template v-slot:text>
        <div class="title">签名验签</div>
        <div class="hint-multi">
          使用签名验签服务不但可以有效地保障业务数据的真实性和有效性，同时还能大大降低应用系统中实现电子签名的复杂度，因此签名验签被广泛应用在电子政务、电子商务、电子金融、医疗卫生等各个行业中。
        </div>
        <router-link :to="{name:'fileVerify'}" class="productConsult">立即验证</router-link>
      </template>
    </Banner>
    <Functional :list="functional" />
    <Features :list="features" />
    <LearnMore />
  </div>
</template>

<script>
import Functional from './components/Functional2.vue'
import Features from './components/Features.vue'
import LearnMore from './components/LearnMore.vue'
export default {
  components: { Features, LearnMore, Functional },
  data () {
    return {
      functional: [
        {
          icon: 'functional2-icon1.png',
          title: '签名验证',
          intro: '提供PKCS#1、国密算法签名等多种格式的签名验证功能',
          image: 'functional2-img6.png'
        },
        {
          icon: 'functional2-icon6.png',
          title: '数字证书验证',
          intro: '提供数字证书验证功能，支持对签名、加密证书进行全面验证。',
          image: 'functional2-img7.png'
        },
        {
          icon: 'functional2-icon7.png',
          title: '身份认证',
          intro: '基于数字证书的身份认证系统，支持不同 CA 系统的证书验证。',
          image: 'functional2-img8.png'
        }
      ],
      features: [
        {
          title: '合法性',
          intro:
            '采用权威第三方认证机构颁发的数字证书，签名验签结果具有法律效力。'
        },
        {
          title: '高可用',
          intro: '支持集群部署，充分保证了系统的稳定运行。'
        },
        {
          title: '兼容性',
          intro:
            '支持各种主流操作系统；面向应用系统提供多语言开发API，方便开发者调用；支持多种格式的数字签名及验证；支持不同认证机构颁发的数字证书。'
        },
        {
          title: '易用性',
          intro:
            '服务端应用接口集成简单、部署便捷，B/S模式管理界面方便管理员轻松配置管理。'
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.functional {
  padding: 160px 0;
  .title {
    font-size: 36px;
    color: #303b50;
    line-height: 48px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 80px;
  }
}
.productConsult {
  display: inline-block;
  width: 102px;
  height: 42px;
  background: #1c67f3;
  border-radius: 4px;
  font-size: 14px;
  color: #ffffff;
  line-height: 42px;
  text-align: center;
  margin-top: 56px;
}
</style>